import React from 'react'
import '../App.css'

function Navbar() {
  return (
    <div className='navbar'>
      <h1>Best  Shopping  App</h1>
    </div>
  )
}

export default Navbar
